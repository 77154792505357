<template>
  <div>
    <!--<div class="top">-->
    <!--<img src=../assets_ch/back.png" alt="" @click="back">-->
    <!--返回-->
    <!--</div>-->
    <div class="header">
      <div class="img">
        <van-image
          width="2.06rem"
          height="2.06rem"
          fit="cover"
          :src="folder.folderImgUrl"
        />
      </div>
      <div class="name-num">
        <p>{{ folder.folderName }}</p>
        <p>
          <span>{{ picList.length != 0 ? picList.length : 0 }}</span
          >{{$t('publicityMaterials.picture.total')}}
        </p>
      </div>
    </div>
    <div class="content">
      <div
        class="content-img"
        v-for="(item, index) of picList"
        :key="'lipc' + index"
        @click="getPicDetails(item, index)"
      >
        <!-- <img
          :src="item.readCultureImg"
          alt=""
          @click="getPicDetails(item, index)"
        /> -->
        <van-image
          width="2.16rem"
          height="2.16rem"
          fit="cover"
          :src="item.readCultureImg"
          @click="getPicDetails(item, index)"
        />
      </div>
    </div>
    <img
      :src="imagesAddressCommon + '/fenxiang.png'"
      class="fenxiang"
      @click="tofenxiang"
      v-show="isshowfenxiang"
    />
  </div>
</template>

<script>
import $ from "jquery";
import { enumsImages } from "@/utils/enum.js";
export default {
  data() {
    return {
      folderId: "",
      picList: [],
      folder: "",
      index: 0,
      isshowfenxiang: false,
      imagesAddressCommon: enumsImages.addressCommon,
      imagesAddressAddressZh: enumsImages.addressZh,
    };
  },
  created() {
    this.folderId = this.$route.query.folderId;
  },
  mounted() {
    this.getPicList();
    this.judgeApp();
  },
  methods: {
    // 判断是否是app内部
    judgeApp() {
      // 需要ios 安卓传递参数
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        try {
          let isApp = window.Android.isApp();
          if (isApp == "android_yuanzilian") {
            this.isshowfenxiang = true;
          } else {
            this.isshowfenxiang = false;
          }
        } catch (err) {
          this.isshowfenxiang = false;
        }
      }
      if (isiOS) {
        try {
          //1、通知IOS 传参数; isApp ios方法
          window.webkit.messageHandlers.isApp.postMessage({});
          window.isApp = this.isApp;
        } catch (err) {
          this.isshowfenxiang = false;
        }
      }
    },
    //获取ios
    isApp: function (value) {
      if (value == "ios_yuanzilian") {
        this.isshowfenxiang = true;
      } else {
        this.isshowfenxiang = false;
      }
    },
    // 根据相册ID获取图片列表
    getPicList() {
      let that = this,
        data = {
          cultureFolderId: this.folderId
        },
        url = "https://official.juhaowu.cn/api/cultureFolder/listByCultureFolderId",
        url_en = "https://en.atoshi.info/api/cultureFolder/listByCultureFolderId";
      $.ajax({
        type: "post", //提交方式
        url: that.$store.state.isEn ? url_en : url, //路径
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify(data), // 数据
        success: function (res) {
          //返回数据根据结果进行相应的处理
          if (res.code == 100) {
            let resData = res.data;
            that.picList = resData.cultures;
            that.folder = resData.cultureFolder;
            // that.checkwhlist();
          }
        },
      });
    },
    checkwhlist() {
      // let that = this;
      setTimeout(function () {
        // for (var j = 0; j < that.picList.length; j++) {
        //   if (
        //     $("#lipc" + j + " img").width() > 0 ||
        //     $("#lipc" + j + " img").height() > 0
        //   ) {
        //     if (
        //       $("#lipc" + j + " img").width() < $("#lipc" + j + " img").height()
        //     ) {
        //       $("#lipc" + j + " img").css({ width: "auto", height: "1.84rem" });
        //     } else {
        //       $("#lipc" + j + " img").css({ width: "2.24rem", height: "auto" });
        //     }
        //   }
        // }
      }, 600);
    },
    // 跳转到图片详情页
    getPicDetails(item, index) {
      let that = this;
      let tpurl = [],
        des = [],
        riqi = [],
        primaryCultureImg = [];
      for (let item of that.picList) {
        tpurl.push(item.readCultureImg ? item.readCultureImg : "无");
        des.push(item.imgDescribe ? item.imgDescribe : "无");
        let a = item.addTime ? item.addTime.substr(0, 10) : "无";
        riqi.push(a);
        primaryCultureImg.push(
          item.primaryCultureImg ? item.primaryCultureImg : "无"
        );
      }
      var userAgent = navigator.userAgent;
      if (/^.+(Mobile\/\w+)\s?$/.test(userAgent)) {
        let datas = JSON.stringify({
          tpurl: tpurl,
          des: des,
          riqi: riqi,
          ind: index,
          primaryCultureImg: primaryCultureImg,
        });
        window.webkit.messageHandlers.showGallery.postMessage(datas);
      } else {
        this.$router.push({
          path: "/picDetails",
          query: {
            foldId: that.folder.cultureFolderId,
            index: index,
            picList: JSON.stringify(item),
            // img:item.readCultureImg,
            // details:item.imgDescribe,
            // time:item.addTime,
            // primaryCultureImg:item.addTime,
          },
        });
      }
    },
    tofenxiang() {
      // let picurl =
      //   "https://chplus.atoshi.org/#/picList?folderId=" + this.folderId;
      let picurl =
        window.location.origin + "/#/picList?folderId=" + this.folderId;
      let _this = this;
      var userAgent = navigator.userAgent;
      if (userAgent.indexOf("Android") > -1) {
        // console.log("picList",picurl)
        window.Android.shareNewsFromWebsite(
          _this.folder.folderName,
          picurl,
          _this.folder.folderImgUrl
        );
      } else if (/^.+(Mobile\/\w+)\s?$/.test(userAgent)) {
        let datas = JSON.stringify({
          fxtitle: _this.folder.folderName,
          fxurl: picurl,
          fxcoverimg: _this.folder.folderImgUrl,
        });
        window.webkit.messageHandlers.shareNewsToiPhone.postMessage(datas);
      }
    },
  },
};
</script>

<style scoped>
.top {
  line-height: 1.3rem;
  height: 1.3rem;
  background: #fff;
  color: #666666;
  font-size: 0.3rem;
  border-bottom: 0.12rem solid #f5f5f5;
}
.top img {
  margin-left: 0.3rem;
  vertical-align: middle;
  width: 0.3rem;
  height: 0.31rem;
}
.header {
  margin-top: 0.45rem;
  margin-left: 0.3rem;
  height: 1.95rem;
  display: flex;
}
.img {
  width: 2.06rem;
  height: 2.06rem;
  /* background: url("/static/img/xiangCeBj.png")no-repeat; */
  /* background-size: 100%; */
  overflow: hidden;
  position: relative;
}
.img img {
  width: 1.95rem;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.name-num {
  margin-left: 0.33rem;
  position: relative;
}
.name-num p:first-of-type {
  width: 4rem;
  position: absolute;
  font-size: 0.28rem;
  color: #333333;
  top: 0;
}
.name-num p:last-of-type {
  width: 4rem;
  position: absolute;
  bottom: 0;
  font-size: 0.24rem;
  color: #999999;
}
.name-num p:last-of-type span {
  font-size: 0.36rem;
  color: #00a0ea;
  padding: 0 0.1rem;
}
.content {
  padding: 0 0.3rem;
  margin-top: 0.8rem;
  display: flex;
  flex-wrap: wrap;
}

.content .content-img {
  width: 2.16rem;
  height: 2.16rem;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
}

.content .content-img:nth-child(3n) {
  margin-right: 0;
}

.content span {
  display: inline-block;
  overflow: hidden;
  margin-bottom: 0.08rem;
  margin-right: 0.08rem;
  width: 2.16rem;
  height: 2.16rem;
  position: relative;
}
.content span img {
  width: 2.24rem;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content span:nth-of-type(3n) {
  margin-right: 0;
}
.fenxiang {
  width: 0.7rem;
  height: 0.7rem;
  position: fixed;
  bottom: 1.1rem;
  right: 0.3rem;
  z-index: 1000;
}
</style>
